import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Newspaper, Shapes } from 'lucide-react';
import { ImperativePanelHandle } from 'react-resizable-panels';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '../resizable/resizable';
import { ExplorerMenu, ExplorerMenuItemProps } from '../explorer-menu/explorer-menu';
import { TopMenu, TopMenuProps } from '../top-menu/top-menu';
import { IdeSidebar, IdeSidebarProps } from '../sidebar/ide-sidebar';
import { SidebarProvider } from '../sidebar/sidebar';
import { DeployLogs } from '@xspecs/design-system/src/ui/deploy-logs/deploy-logs';
import { CanvasToolbarBottomPane } from '@xspecs/client/src/components/canvas-toolbar/canvas-toolbar-bottom-pane/canvas-toolbar-bottom-pane';
import { cn, useStoreContext } from '@xspecs/design-system';
import { debounce } from '@xspecs/design-system/src/ui/ux-sketch-components/utils/debounce';
import { BuildLogState } from '@xspecs/single-source-model/src/read-models/build-log/BuildLog.types';
import { DockViewProps } from '@xspecs/design-system/src/ui/dock-view/dock-view';

export interface IdeLayoutProps {
  topMenu: TopMenuProps;
  sidebar: IdeSidebarProps;
  dockview: DockViewProps;
  children: React.ReactNode;
  logs: BuildLogState | undefined;
  isModelFile: boolean;
}

const PANEL_DEFAULTS = {
  sidebarSize: 20,
  sidebarMinSize: 10,
  minToolbarSize: 20,
  minLogsSize: 15,
  debounceDelay: 300,
};

export const IdeLayout = (props: IdeLayoutProps) => {
  const { topMenu, sidebar, dockview, children, logs, isModelFile } = props;
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [logType, setLogType] = useState('');
  const sidebarRef = useRef<ImperativePanelHandle>(null);
  const logsRef = useRef<ImperativePanelHandle>(null);
  const toolbarRef = useRef<ImperativePanelHandle>(null);
  const childrenRef = useRef<ImperativePanelHandle>(null);

  const { formatMessage: f } = useIntl();
  const { store } = useStoreContext();
  const { ideSidebar, logsPanel, toolbarPanel, setPanels, devMode, toolbar } = {
    ideSidebar: store.use.panels()['ide-sidebar'],
    logsPanel: store.use.panels()['logs'],
    toolbarPanel: store.use.panels()['toolbar'],
    setPanels: store.use.setPanels(),
    devMode: store.use.devMode(),
    toolbar: store.use.toolbar(),
  };

  const isToolbarCollapsed = !toolbar.isOpen;
  const isCompactMode = toolbar.compactMode;

  const debouncedSidebarSize = debounce(
    (size: number) => setPanels('ide-sidebar', 'size', size),
    PANEL_DEFAULTS.debounceDelay,
  );
  const debouncedLogsSize = debounce((size: number) => setPanels('logs', 'size', size), PANEL_DEFAULTS.debounceDelay);
  const debouncedToolbarSize = debounce((size: number) => {
    if (size >= 20) {
      setPanels('toolbar', 'size', size);
    } else if (size < 20 && logsRef.current && logsPanel.collapsed) {
      setPanels('logs', 'collapsed', true);
      logsRef.current.collapse();
    }
  }, PANEL_DEFAULTS.debounceDelay);
  const debouncedLogsCollapse = useCallback(
    (collapsed: boolean) => setPanels('logs', 'collapsed', collapsed),
    [setPanels],
  );

  useEffect(() => {
    if (ideSidebar?.collapsed && sidebarRef.current) {
      sidebarRef.current.collapse();
    }
  }, [ideSidebar?.collapsed]);

  useEffect(() => {
    if (logsPanel?.collapsed && logsRef.current) {
      logsRef.current.collapse();
    }
  }, [logsPanel?.collapsed]);

  useEffect(() => {
    if (!toolbarRef.current) return;
    if (isCompactMode) {
      setPanels('toolbar', 'compact', true);
    } else {
      setPanels('toolbar', 'compact', false);
    }
    if (logsRef.current && logsPanel.collapsed) {
      logsRef.current.collapse();
    }
  }, [isCompactMode, logsPanel.collapsed, setPanels, toolbarPanel?.size]);

  const toggleSidebarPanel = useCallback(() => {
    const panel = sidebarRef.current;
    if (!panel) return;
    const willCollapse = !panel.isCollapsed();
    panel[willCollapse ? 'collapse' : 'expand']();
    setPanels('ide-sidebar', 'collapsed', willCollapse);
  }, [setPanels]);

  const toggleLogsPanel = useCallback(
    (type: string, open?: boolean) => {
      const panel = logsRef.current;
      if (!panel) return;
      const shouldExpand = open || panel.isCollapsed();
      panel[shouldExpand ? 'expand' : 'collapse']();
      setPanels('logs', 'collapsed', !shouldExpand);
      setLogType(type);
    },
    [setPanels],
  );

  const explorerMenuTopItems = useMemo<ExplorerMenuItemProps[]>(
    () => [
      {
        name: f({ id: 'file-explorer' }),
        icon: Shapes,
        active: !sidebarCollapsed,
        onClick: toggleSidebarPanel,
        variant: 'primary',
      },
    ],
    [f, sidebarCollapsed, toggleSidebarPanel],
  );

  const explorerMenuBottomItems = useMemo<ExplorerMenuItemProps[]>(
    () => [
      {
        name: f({ id: 'logs' }),
        icon: Newspaper,
        active: !logsPanel.collapsed,
        onClick: () => toggleLogsPanel('Logs'),
        variant: 'secondary',
      },
    ],
    [f, logsPanel, toggleLogsPanel],
  );

  return (
    <ResizablePanelGroup direction="horizontal">
      <SidebarProvider>
        <div className="flex w-screen">
          <ExplorerMenu
            logout={sidebar.logout}
            user={sidebar.user}
            showUser={sidebarCollapsed}
            bottomItems={explorerMenuBottomItems}
            topItems={explorerMenuTopItems}
            viewSpaces={topMenu.viewSpaces || (() => {})}
          />
          <div className="flex-1 flex flex-col max-w-[calc(100%_-_32px)]">
            <TopMenu {...topMenu} toggleLogsPanel={toggleLogsPanel} />
            <div className="flex h-full">
              <ResizablePanel
                id="ide-sidebar"
                order={1}
                ref={sidebarRef}
                className="flex"
                defaultSize={ideSidebar?.size || PANEL_DEFAULTS.sidebarSize}
                minSize={PANEL_DEFAULTS.sidebarMinSize}
                collapsible
                onCollapse={() => setSidebarCollapsed(true)}
                onExpand={() => setSidebarCollapsed(false)}
                onResize={debouncedSidebarSize}
              >
                <IdeSidebar {...sidebar} collapsed={sidebarCollapsed} collapse={toggleSidebarPanel} />
              </ResizablePanel>
              <ResizableHandle />
              <ResizablePanel order={2}>
                <ResizablePanelGroup direction="vertical">
                  <ResizablePanel id="file-panel" order={3} ref={childrenRef}>
                    {children}
                  </ResizablePanel>
                  {!isToolbarCollapsed && isModelFile ? (
                    <>
                      <ResizableHandle disabled={toolbarPanel?.compact} />
                      <ResizablePanel
                        ref={toolbarRef}
                        id="toolbar"
                        order={4}
                        collapsible={toolbarPanel?.collapsed}
                        minSize={20}
                        defaultSize={toolbarPanel?.size || 0}
                        onResize={debouncedToolbarSize}
                        collapsedSize={toolbarPanel?.compact ? 4 : 20}
                        className={cn(toolbarPanel?.compact ? 'min-h-[70px] max-h-[70px]' : 'min-h-[200px] max-')}
                      >
                        <CanvasToolbarBottomPane {...toolbar.layout.body} />
                      </ResizablePanel>
                    </>
                  ) : null}
                  {devMode && (
                    <ResizableHandle
                      className={cn('bg-border dark:bg-border', logsPanel.collapsed ? 'hidden' : 'block')}
                    />
                  )}
                  {devMode && (
                    <ResizablePanel
                      id="ide-logs"
                      order={5}
                      ref={logsRef}
                      collapsible
                      minSize={PANEL_DEFAULTS.minLogsSize}
                      defaultSize={logsPanel?.size || 0}
                      onCollapse={() => debouncedLogsCollapse(true)}
                      onExpand={() => debouncedLogsCollapse(false)}
                      onResize={debouncedLogsSize}
                    >
                      <DeployLogs type={logType} onClose={toggleLogsPanel} logs={logs} />
                    </ResizablePanel>
                  )}
                </ResizablePanelGroup>
              </ResizablePanel>
            </div>
          </div>
        </div>
      </SidebarProvider>
    </ResizablePanelGroup>
  );
};
