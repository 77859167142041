import { Avatar, AvatarImage, AvatarFallback } from '@xspecs/design-system';
import { ActiveUser } from '@xspecs/single-source-model';
import { getInitials } from '../../../../lib/utils';

type NodeActiveUser = {
  user?: ActiveUser;
};

export const NodeActiveUser = (props: NodeActiveUser) => {
  const { user } = props;

  if (!user) return null;

  return (
    <Avatar className="absolute -bottom-[6px] -right-[6px] z-10 w-3 h-3">
      <AvatarImage src={user.picture} />
      <AvatarFallback>{getInitials(user.name)}</AvatarFallback>
    </Avatar>
  );
};
