import { DEBUG_CONFIG } from '@xspecs/single-source-model';

export const MIN_FONT_SIZE = 3;
export const MAX_FONT_SIZE = 36;

function isSquare(textArea: HTMLTextAreaElement) {
  const style = window.getComputedStyle(textArea);
  return style.width === style.height;
}

function doesTextFit(text: string, textarea: HTMLTextAreaElement, fontSize: number, width: number, height: number) {
  const clone: HTMLTextAreaElement = textarea.cloneNode() as HTMLTextAreaElement;
  clone.style.position = 'absolute';
  clone.style.visibility = 'hidden';
  if (DEBUG_CONFIG.fontResizer) {
    clone.style.position = 'absolute';
    clone.style.backgroundColor = 'black';
    clone.style.color = 'white';
    clone.style.visibility = 'visible';
    clone.style.top = '5000px';
    clone.style.left = '5000px';
  }
  clone.style.width = width + 'px';
  clone.style.height = height + 'px';
  clone.style.fontSize = fontSize + 'px';
  clone.value = text;
  document.body.appendChild(clone);
  const scrollWidth = clone.scrollWidth;
  const scrollHeight = clone.scrollHeight;
  if (DEBUG_CONFIG.fontResizer) setTimeout(() => document.body.removeChild(clone), 500);
  else document.body.removeChild(clone);
  return scrollWidth <= width && scrollHeight <= height;
}

export const getFittingFontSize = (
  text: string,
  textarea: HTMLTextAreaElement,
  containerDiv: HTMLDivElement,
  maxFontSize: number,
  minFontSize: number,
) => {
  let newFontSize = maxFontSize;
  while (!doesTextFit(text, textarea, newFontSize, containerDiv.clientWidth, containerDiv.clientHeight))
    if (--newFontSize <= minFontSize) break;
  return newFontSize;
};

export function getMaxLength(textArea: HTMLTextAreaElement) {
  return isSquare(textArea) ? 1066 : 1976;
}

export const getTextAreaHeight = (container: HTMLDivElement, textArea: HTMLTextAreaElement) => {
  return textArea.scrollHeight > container.clientHeight ? container.clientHeight : textArea.scrollHeight;
};
