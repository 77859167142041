import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { jwtDecode } from 'jwt-decode';
import { CacheEntry, LocalStorageCache } from '../auth';
import { AUTH0_LOCAL_TOKEN_KEY } from '../config/auth0/Auth0Config';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getUser() {
  const localStorageCache = new LocalStorageCache();
  const result = localStorageCache.get(AUTH0_LOCAL_TOKEN_KEY) as CacheEntry;

  if (!result?.decodedToken) return null;

  const { user } = result.decodedToken;

  return {
    id: user.sub,
    username: user.name || user.nickname,
    email: user.email,
    // ip_address?: string;
    // segment?: string;
  };
}

export function getFlags(): Array<string> {
  const localStorageCache = new LocalStorageCache();
  const result = localStorageCache.get(AUTH0_LOCAL_TOKEN_KEY) as CacheEntry;
  if (!result || !result.id_token) return [];
  const decodedIdToken = jwtDecode(result.id_token);

  const [key] = Object.keys(decodedIdToken).filter((key) => key.endsWith('user_metadata'));
  if (!key) return [];
  return decodedIdToken[key].FF ?? [];
}

export const LAST_SELECTED_ORGANIZATION_ID_KEY = 'lastSelectedOrganization';
export const LAST_SELECTED_WORKSPACE_BY_ORG = 'lastSelectedWorkspaceByOrg';
export const HAS_USER_VISITED = 'hasUserVisited';

export const px = (value: string | number) => (value.toString().endsWith('px') ? value.toString() : `${value}px`);

export const isUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export const isAutoUser = () => {
  const user = getUser();
  return Boolean(
    user?.email?.endsWith('@on.auto') || user?.email?.endsWith('@xspecs.ai') || user?.email?.endsWith('@xolv.io'),
  );
};

export const getInitials = (name: string): string => {
  const parts = name.trim().split(/\s+/);

  if (parts.length === 0) return '';
  if (parts.length === 1) return parts[0][0].toUpperCase();
  if (parts.length === 2) return (parts[0][0] + parts[1][0]).toUpperCase();

  return (parts[0][0] + parts[parts.length - 1][0]).toUpperCase();
};
