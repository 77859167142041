import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { AttachmentItem } from '../attachment-item/attachment-item';
import { Box, IconButton, SxProps, Theme } from '@mui/material';
import {
  FocusEventHandler,
  Fragment,
  memo,
  MouseEventHandler,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AttachmentNodeData, EntityType, Upload } from '@xspecs/single-source-model';
import { useApplication } from '../../../../../wrappers/application-context/application-context';
import { cn, Icon } from '@xspecs/design-system';
import { FloatingMenu } from '../../floating-menu/floating-menu';
import {
  arrow,
  autoPlacement,
  autoUpdate,
  FloatingArrow,
  offset,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { useActiveOrganization } from '../../../../../hooks/use-active-organization';
import { FloatingDetailsView } from '../../floating-details-view/floating-details-view';
import { ReactFlowRendererPortal } from '../../reactflow-renderer-portal/reactflow-renderer-portal';
import { useIntl } from 'react-intl';
import { useLocalStorage } from '@mantine/hooks';
import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import { NodeActiveUser } from '../../node-active-user/node-active-user';

export const AttachmentNode = (props: NodeProps<Node<AttachmentNodeData>>) => {
  const { id, type, selected, data } = props;
  const { application } = useApplication();

  const [showFloatingMenu, setShowFloatingMenu] = useState(false);

  const { organization } = useActiveOrganization();
  const [isOpen, setIsOpen] = useState(false);
  const [floatingViewClicked, setFloatingViewClicked] = useState(false);
  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'right',
    middleware: [
      offset(20),
      autoPlacement({
        allowedPlacements: ['right'],
      }),
      arrow({
        element: arrowRef.current,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);
  const { formatMessage: f } = useIntl();

  const [clickCount, setClickCount] = useState(0);
  const defaultFloatingViewDimensions = { width: 555, height: 470 };
  const [_floatingViewDimensions, setFloatingViewDimensions] = useLocalStorage({
    key: `floating-view-dimensions`,
    defaultValue: {
      [id]: defaultFloatingViewDimensions,
    },
  });
  const floatingViewDimensions = _floatingViewDimensions[id] ?? defaultFloatingViewDimensions;

  const onDetailsViewResize = useCallback(
    (e: SyntheticEvent, result: ResizeCallbackData) => {
      setFloatingViewDimensions({
        ..._floatingViewDimensions,
        [id]: {
          width: result.size.width,
          height: result.size.height,
        },
      });
    },
    [_floatingViewDimensions, id, setFloatingViewDimensions],
  );

  const [resizing, setResizing] = useState(false);

  const onShowDetails = useCallback(() => {
    setIsOpen(true);
    setShowFloatingMenu(false);
  }, []);

  const onToggleEntityVisibility = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation();
      application?.getModelContext()?.interactor.toggleEntityVisibilityExpanded(id);
    },
    [application, id],
  );

  const onWrapperClick = useCallback<MouseEventHandler<HTMLDivElement>>((event) => {
    setClickCount(Math.min(event.detail, 2));
    setShowFloatingMenu(true);
  }, []);

  const onWrapperBlur = useCallback<FocusEventHandler<HTMLDivElement>>(() => {
    setClickCount(0);
  }, []);

  const wrapperSx = useMemo<SxProps<Theme>>(
    () => ({
      backgroundColor: 'transparent',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1,
    }),
    [],
  );

  const detailsViewEntity = useMemo(() => {
    if (!data.asset?.fileConfig?.type) return null;
    return {
      id: organization!.id + '/' + id,
      type: data.type,
      name: data.name,
      editor: data.asset?.fileConfig.type,
    };
  }, [data.asset?.fileConfig?.type, data.name, data.type, id, organization]);

  useEffect(() => {
    if (!selected) {
      setClickCount(0);
      setShowFloatingMenu(false);
    }
  }, [selected]);

  return (
    <>
      <NodeActiveUser user={data.selectedBy} />
      <div ref={refs.setReference} className="relative size-full" {...getReferenceProps()}>
        <Box position="relative" onClick={onWrapperClick} onBlur={onWrapperBlur}>
          {clickCount < 1 ? <Box sx={wrapperSx} /> : null}
          <AttachmentItem attachmentId={id} data={data} selected={Boolean(selected)} hasOverlay={data.hasOverlay} />
        </Box>
        {data.hasParent && !data.isDragging ? <Staples type={data.subType} /> : null}
      </div>
      {data.subType === EntityType.Upload ? (
        <IconButton sx={expandButtonSx} onClick={onToggleEntityVisibility}>
          <Icon name="expand-image" width={20} height={20} />
        </IconButton>
      ) : null}
      {data.asset ? (
        <FloatingMenu
          id={data.asset.id}
          type={type}
          labels={data.labels}
          disableLabels
          name={data.name}
          withCopy
          subType={data.subType}
          url={data.asset instanceof Upload ? data.asset.url : undefined}
          onShowDetails={onShowDetails}
          isVisible={showFloatingMenu}
        />
      ) : null}
      {data.subType === EntityType.Upload ? <Handles /> : null}
      {isOpen && detailsViewEntity ? (
        <ReactFlowRendererPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className={cn('z-10', !resizing && floatingViewClicked && 'nodrag nowheel')}
            onClick={() => setFloatingViewClicked(true)}
            {...getFloatingProps()}
          >
            <ResizableBox
              width={floatingViewDimensions.width}
              height={floatingViewDimensions.height}
              onResize={onDetailsViewResize}
              handle={<span className="custom-handle custom-handle-se" />}
              onResizeStart={() => setResizing(true)}
              onResizeStop={() => setResizing(false)}
            >
              <FloatingArrow ref={arrowRef} context={context} />
              {resizing ? (
                <div className="size-full bg-background-grey items-center justify-center flex rounded-xl">
                  <p>{f({ id: 'resizing' })}</p>
                </div>
              ) : (
                <FloatingDetailsView key={`FloatingDetailsView${id}${resizing}`} entity={detailsViewEntity} />
              )}
            </ResizableBox>
          </div>
        </ReactFlowRendererPortal>
      ) : null}
    </>
  );
};

const Handles = () => {
  return (
    <Fragment>
      <Handle id={Position.Left} type="source" position={Position.Left} style={style} />
      <Handle id={Position.Right} type="source" position={Position.Right} style={style} />
      <Handle id={Position.Top} type="source" position={Position.Top} style={style} />
      <Handle id={Position.Bottom} type="source" position={Position.Bottom} style={style} />
    </Fragment>
  );
};

const style = {
  opacity: 0,
};

const Staples = memo(({ type }: { type: string }) => {
  const top = type === EntityType.Actor ? 26 : -12;
  return (
    <Fragment>
      <div
        style={{
          width: '2px',
          minWidth: '2px',
          height: 12,
          backgroundColor: '#aeaeae',
          position: 'absolute',
          top,
          left: `${20}%`,
        }}
      />
      <div
        style={{
          width: '2px',
          minWidth: '2px',
          height: 12,
          backgroundColor: '#aeaeae',
          position: 'absolute',
          top,
          left: `${22}%`,
        }}
      />
      <div
        style={{
          width: '2px',
          minWidth: '2px',
          height: 12,
          backgroundColor: '#aeaeae',
          position: 'absolute',
          top,
          left: `${80}%`,
        }}
      />
      <div
        style={{
          width: '2px',
          minWidth: '2px',
          height: 12,
          backgroundColor: '#aeaeae',
          position: 'absolute',
          top,
          left: `${82}%`,
        }}
      />
    </Fragment>
  );
});
Staples.displayName = 'Staples';

const expandButtonSx = {
  p: 0.25,
  position: 'absolute',
  right: '5px',
  top: '50%',
  transform: 'translate(50%, -50%)',
  zIndex: 10,
  backgroundColor: 'background.paper',
  border: '1px solid #aeaeae',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: 'background.paper',
  },
};
